<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="assignBdoCardForm"
        ref="assignBdoCardForm"
        class="custom-form pt-6"
        @submit.prevent="assign"
      >
        <v-col cols="12">
          <v-text-field
            :value="client && client.name"
            label="Klient"
            placeholder="Klient"
            outlined
            dense
            readonly
          />
        </v-col>

        <v-col cols="12">
          <v-menu
            v-model="showDatePicker"
            v-bind="attributes.menu"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="formattedValue"
                label="Data"
                placeholder="Wybierz datę"
                v-bind="{...attrs, ...attributes.textField}"
                v-on="on"
              >
                <template #prepend-inner>
                  <Icon
                    name="date"
                    size="small"
                    class="mr-2"
                  />
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              v-bind="attributes.datePicker"
              @input="showDatePicker = false"
              @change="fetchCourses"
            />
          </v-menu>
        </v-col>

        <v-col cols="12">
          <v-autocomplete
            v-model="courseId"
            :items="courses"
            item-value="id"
            item-text="address.formattedAddress"
            :rules="[rules.required]"
            label="Kurs"
            placeholder="Wybierz kurs"
            outlined
            dense
          />
        </v-col>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="assignBdoCardForm"
      >
        Przypisz
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'
import { dateStringFormat } from '../../utils'
import rules from '../../utils/validators'
import attributes from '../../const/datePickerAttrributes'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'

export default {
  components: {
    DialogFormWrapper
  },
  mixins: [afterFormSubmitted],
  data () {
    return {
      rules,
      attributes,
      showDatePicker: false,
      courseId: null,
      client: null,
      date: new Date().toISOString().substr(0, 10)
    }
  },
  computed: {
    ...mapState({
      courses: state => state.courses.items,
      department: state => state.core.department,
      bdoCard: state => state.bdoCard.entity,
      isProcessing: state => state.bdoCards.isProcessing,
    }),
    formattedValue: {
      get () {
        return this.date ? dateStringFormat(this.date) : ''
      },
      set (value) {
        this.date = value
      }
    }
  },
  created () {
    this.client = this.bdoCard?.client
    this.fetchCourses()
  },
  methods: {
    ...mapActions({
      getCourses: 'courses/getItems',
      assignCourseToBdoCard: 'bdoCard/assignCourseToBdoCard',
    }),
    fetchCourses () {
      const params = {
        departmentId: this.department.id,
        filters: {
          withSwapBase: true,
          anyContainer: true,
          clientId: this.client?.id,
          daterange: this.date ? [this.date] : undefined
        }
      }
      this.getCourses({ params })
        .then(() => {
          this.courseId = null
        })
    },
    assign () {
      if (this.$refs.assignBdoCardForm.validate()) {
        this.assignCourseToBdoCard({ courseId: this.courseId })
          .then(() => { this.afterFormSubmitted('Przypisano kartę') })
      }
    }
  }
}
</script>
